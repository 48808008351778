// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap";
import "chartkick/chart.js";
import Rails from '@rails/ujs';

Rails.start();

global.deactivate_submit_button = function() {
    let submit_btn = document.getElementById('submit_registration');
    submit_btn.setAttribute('disabled', 'disabled');
    submit_btn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="visually-hidden">Loading...</span>';
    document.getElementById('registration_form').submit();
}
